import React, { useEffect,useState } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, change } from 'redux-form';
import { useSelector } from 'react-redux';

import { userPropTypes } from 'helpers/propTypes';
import { getLocalStorage } from 'helpers/storage';

import Avatar from '@material-ui/core/Avatar';
import ShoppingCart from '@material-ui/icons/ShoppingCart';

import Input from 'components/commons/Input';
import FileInput from 'components/commons/FileInput';
import Button from 'components/commons/Button';

import mercadoPagoImage from 'views/Checkout/assets/mercado-pago.png';

import './PickupForm.scss';

const validate = (values) => {
  const errors = {};
  if (!values.first_name_retire) {
    errors.first_name_retire = 'Nombre inválido';
  }
  if (!values.last_name_retire) {
    errors.last_name_retire = 'Apellido inválido';
  }
  if (!values.id_card_retire || !/^[0-9]*$/i.test(values.id_card_retire)) {
    errors.id_card_retire = 'DNI inválido';
  }
  if (!values.email_retire || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email_retire)) {
    errors.email_retire = 'Email inválido';
  }
  if (!values.phone_retire || !/^(54)?[1-9]\d{9}$/i.test(values.phone_retire)) {
    errors.phone_retire = 'Número inválido (Ej: 541123456789)';
  }
  return errors;
};

const PickupForm = (props) => {
  const {
    handleSubmit, initialize, onSubmit, submitting, user,
    couponText, setCouponText, onSubmitCoupon, coupon, emailComplete,
    setEmailComplete, validationEmailCoupon, setEmailCoupon, onSecureCheckoutClick,
    showMPButton, transferInfo, dispatch, subtotal,onSubmitTransfer,
  } = props;
  const [transfer, setTransfer] = useState(false);
  const formValues = useSelector((state) => state.form.pickup);
  useEffect(() => {
    if (user) {
      initialize({
        first_name_retire: user.first_name,
        last_name_retire: user.last_name,
        email_retire: user.email,
        phone_retire: user.phone,
      });
      setEmailComplete(true);
      setEmailCoupon(user.email);
    }
    // eslint-disable-next-line
  }, [user, setEmailComplete, setEmailCoupon]);

  const onKeyPress = (event) => {
    if (event.key === 'Enter') {
      onSubmitCoupon();
    }
  };

  const handleBack = () => {
    dispatch(change('pickup', 'comprobante', ''));
    setTransfer(false);
  };

  const handleTransferClick = () => {
    // Validar el formulario al hacer clic en "PAGAR POR TRANSFERENCIA"
    handleSubmit((values) => {
      const errors = validate(values);
      if (Object.keys(errors).length === 0) {
        setTransfer(true); // Mostrar el modal si no hay errores
      }
    })();
  };

  const discount = getLocalStorage('discount');
  const percentageOrAmount = coupon?.percentage
    ? (coupon.percentage / 100) * subtotal
    : (coupon?.amount || 0);
  const totalValueWithDiscount = (discount - percentageOrAmount) > 0
    ? discount - percentageOrAmount
    : 0;
  const totalValueWithoutDiscount = (subtotal - percentageOrAmount) > 0
    ? subtotal - percentageOrAmount
    : 0;
  const totalValueWithPercetage = subtotal - percentageOrAmount;
  return (
    <div className="container" data-testid="pickup-form">
      <div className="row">
        <div className="form-container">
          <Avatar className="avatar">
            <ShoppingCart />
          </Avatar>
          <h4>Retiro en sucursal Lenceria Avellaneda (Av. Avellaneda 3132)</h4>
          <form className="redux-form" encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
            <span>Datos de la persona que retirar el pedido</span>
            <Field
              component={Input}
              label="Nombre"
              name="first_name_retire"
            />
            <Field
              component={Input}
              label="Apellido"
              name="last_name_retire"
            />
            <Field
              component={Input}
              label="DNI"
              name="id_card_retire"
              type="number"
            />
            <Field
              component={Input}
              label="Email"
              disabled={!!user}
              name="email_retire"
              onChange={(event) => validationEmailCoupon(event.target.value)}
              type="email"
            />
            <Field
              component={Input}
              label="Celular / WhatsApp"
              name="phone_retire"
              type="number"
            />
            <Field
              component={Input}
              label="Transporte de preferencia / Comentarios"
              isTextArea
              name="comments"
            />
            <Field
              component={Input}
              label="Desea recibir novedades"
              name="suscribe"
              type="checkbox"
            />
            <div>
              <Field
                component={Input}
                label="Aplicar Cupon"
                name="coupon"
                disabled={!!coupon || !emailComplete}
                value={couponText}
                onChange={(event) => setCouponText(event.target.value)}
                onKeyPress={onKeyPress}
              />
              <Button
                buttonClass={!coupon ? 'btn-search-set' : 'btn-search-reset'}
                disabled={!emailComplete}
                text={
                  !coupon ? ' Aplicar Cupon ' : <i className="fa fa-trash-o" aria-hidden="true" />
                }
                type="button"
                onClick={onSubmitCoupon}
              />
            </div>

            <Button text="Arreglar pago con el vendedor" type="button" onClick={handleSubmit(onSubmit)} buttonClass="btn-block" disabled={submitting} />
            {showMPButton && (
              <Button
                text={(
                  <div className="secure-checkout-button-content justify-content-center">
                    <img src={mercadoPagoImage} alt="mercado pago" height="18" />
                    {' '}
                    Pagar con Mercado Pago
                  </div>
                )}
                type="button"
                onClick={handleSubmit(onSecureCheckoutClick)}
                buttonClass="btn-block secure-checkout-button"
                disabled={submitting}
              />
            )}
            {transferInfo ? <>{transferInfo.hide ? <Button text="PAGAR POR TRANSFERENCIA" buttonClass="btn-block" onClick={() => handleTransferClick()} childrenClass='children-Class'  children={transferInfo.discount != 0 &&  `Descuento: ${transferInfo.porcentaje ? transferInfo.discount + '%':'$' + transferInfo.discount}`} /> : <></>}
              {transfer ?
                <div className="modal-confirmCart-overlay">
                  <div className="modal-confirmCart">
                    <div className="modal-confirmCart-content">
                      <h3 className="modal-title">{transferInfo.title}</h3>
                      <p className="modal-description">{transferInfo.description}</p>
                      {!!transferInfo.discount &&  
                      <p className="modal-info">Descuento por pago por transferencia: {transferInfo.porcentaje ? transferInfo.discount + '%':'$' + transferInfo.discount}</p>}
                      <p className="modal-info">{transferInfo.take_discounts ? 'Modo de pago compatible con otros descuentos' : 'Modo de pago no compatible con otros descuentos'}</p>
                      <p className="modal-description">Monto total a pagar:  ${
                        transferInfo.porcentaje ?
                        discount === null || !transferInfo.take_discounts
                        ? (
                          totalValueWithoutDiscount.toFixed(2) * (1-(transferInfo.discount/100))
                        )
                        : (
                              coupon?.percentage ? (
                                totalValueWithPercetage.toFixed(2) * (1-(transferInfo.discount/100))
                              ) : (
                                totalValueWithDiscount.toFixed(2) * (1-(transferInfo.discount/100))
                              )
                        ) :
                        discount === null || !transferInfo.take_discounts
                        ? (
                          totalValueWithoutDiscount.toFixed(2) - transferInfo.discount
                        )
                        : (
                              coupon?.percentage ? (
                                totalValueWithPercetage.toFixed(2) - transferInfo.discount
                              ) : (
                                totalValueWithDiscount.toFixed(2) - transferInfo.discount
                              )
                        )}</p>
                      {/*<Field
                        component={FileInput}
                        label="Comprobante de pago"
                        name="comprobante"
                        type="file"
                      />*/}
                    </div>
                    <div className="modal-confirmCart-actions">
                      <button className="btn-transfer" onClick={handleBack} disabled={submitting}>
                        Volver
                      </button>
                      <button className="btn-transfer" onClick={handleSubmit(onSubmitTransfer)}>
                        Confirmar
                      </button>
                    </div>
                  </div>
                </div>
              :<></>}</> : <></>}
          </form>
        </div>
      </div>
    </div>
  );
};

PickupForm.defaultProps = {
  user: null,
  coupon: null,
  couponText: '',
  emailComplete: false,
};

PickupForm.propTypes = {
  coupon: PropTypes.shape({
    id: PropTypes.number.isRequired,
    percentage: PropTypes.number.isRequired,
  }),
  handleSubmit: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  user: userPropTypes,
  couponText: PropTypes.string,
  setCouponText: PropTypes.func.isRequired,
  emailComplete: PropTypes.bool,
  onSubmitCoupon: PropTypes.func.isRequired,
  setEmailComplete: PropTypes.func.isRequired,
  setEmailCoupon: PropTypes.func.isRequired,
  validationEmailCoupon: PropTypes.func.isRequired,
  onSecureCheckoutClick: PropTypes.func.isRequired,
  showMPButton: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'pickup',
  validate,
})(PickupForm);
