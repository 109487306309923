// Api endpoint
export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

export const MINIMUM_PURCHASE_DELIVERY_FREE = parseInt(
  process.env.REACT_APP_MINIMUM_PURCHASE_DELIVERY_FREE,
  10,
);

export const MERCADO_PAGO_PUBLIC_KEY = process.env.REACT_APP_MERCADO_PAGO_PUBLIC_KEY;
