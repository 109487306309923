import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { cartItemsArrayPropTypes } from 'helpers/propTypes';
import { removeLocalStorage, setLocalStorage } from 'helpers/storage';
import { getProducts, updateCart } from 'redux/actions/cart.actions';
import { openProductModal } from 'redux/actions/modal.actions';
import {
  cartItemIdsSelector, cartItemsSelector, cartSizeSelector, cartSubtotalSelector,
} from 'redux/selectors/cart.selector';
import { getDiscount } from 'services/app.services';

import Cart from 'views/Cart';

const CartContainer = (props) => {
  const {
    cartSize, getProductsAction, ids, items, loading, minimumPurchase, openProductModalAction,
    updateCartAction, subtotal,
  } = props;
  const [initialized, setInitialized] = useState(false);
  const [priceDiscount, setPriceDiscount] = useState(null);
  const [, setRefresh] = useState(Math.random());

  useEffect(() => {
    if (ids.length > 0 && !initialized) {
      getProductsAction(ids);
      setInitialized(true);
    }
    return () => getProductsAction([]);
    // eslint-disable-next-line
  }, [getProductsAction, JSON.stringify(ids)]);

  const getDiscounts = async () => {
    const { items: discounts } = await getDiscount();
    if (discounts.length > 0) {
      let newSubtotal = subtotal;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < discounts.length; i++) {
        if (subtotal > discounts[i].amount) {
          newSubtotal = subtotal - (subtotal * discounts[i].percentage) / 100;
          setLocalStorage('percentage', discounts[i].percentage);
        }
      }
      if (newSubtotal !== subtotal) {
        setLocalStorage('discount', newSubtotal);
        setPriceDiscount(newSubtotal);
      } else {
        removeLocalStorage('discount');
        removeLocalStorage('percentage');
        setPriceDiscount(null);
      }
    } else {
      removeLocalStorage('discount');
      removeLocalStorage('percentage');
      setPriceDiscount(null);
    }
    setRefresh(Math.random());
  };

  useEffect(() => {
    if (subtotal > 0) getDiscounts();
    // eslint-disable-next-line
  }, [subtotal, cartSize]);

  const onQuantityChange = (item, value, description, wholesale) => {
    if (description) {
      updateCartAction(item.id, value, description.id, wholesale);
    } else {
      updateCartAction(item.id, value, null, wholesale);
    }
  };

  return (
    <Cart
      cartSize={cartSize}
      items={items}
      loading={loading}
      minimumPurchase={minimumPurchase}
      onQuantityChange={onQuantityChange}
      onRemoveFromCart={(item) => { updateCartAction(item.id, 0, null, null); getDiscount(); }}
      subtotal={subtotal}
      priceDiscount={priceDiscount}
      onItemClick={(item) => openProductModalAction(item)}
    />
  );
};

const mapStateToProps = (state) => ({
  cartSize: cartSizeSelector(state),
  ids: cartItemIdsSelector(state),
  items: cartItemsSelector(state),
  loading: state.cart.loading,
  minimumPurchase: state.app.minimumPurchase,
  subtotal: cartSubtotalSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  getProductsAction: bindActionCreators(getProducts, dispatch),
  openProductModalAction: bindActionCreators(openProductModal, dispatch),
  updateCartAction: bindActionCreators(updateCart, dispatch),
});

CartContainer.propTypes = {
  cartSize: PropTypes.number.isRequired,
  getProductsAction: PropTypes.func.isRequired,
  ids: PropTypes.arrayOf(PropTypes.number).isRequired,
  items: cartItemsArrayPropTypes.isRequired,
  loading: PropTypes.bool.isRequired,
  minimumPurchase: PropTypes.number.isRequired,
  subtotal: PropTypes.number.isRequired,
  updateCartAction: PropTypes.func.isRequired,
  openProductModalAction: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CartContainer);
