import { NotificationManager } from 'react-notifications';

export const sendErrorNotification = (message) => {
  NotificationManager.removeAll();
  NotificationManager.error(message, null, 7000);
};

export const sendInfoNotification = (message) => {
  NotificationManager.removeAll();
  NotificationManager.info(message, null, 7000);
};

export const sendWarningNotification = (message) => {
  NotificationManager.removeAll();
  NotificationManager.warning(message, null, 7000);
};

export const sendSuccessNotification = (message) => {
  NotificationManager.removeAll();
  NotificationManager.success(message, null, 7000);
};
