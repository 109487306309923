import React from 'react';
import ScrollMenu from 'react-horizontal-scroll-menu';
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { productsArrayPropTypes, slidersArrayPropTypes } from 'helpers/propTypes';
import { sectionsArray } from 'views/Home/constants';

import Loading from 'components/commons/Loading';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './Home.scss';

const Home = (props) => {
  const {
    cartel, categoriesHome, currentSection, currentSection1, instagram, items, loadingProd, loadingMostSelled,
    marcasHome, mostSelled, mostSelledClick, offers, onSectionClick, renderItem, setNewsletter,
    sliders, subCategoriesHome, submitNewsletter, 
  } = props;

  const renderSilder = () => (
    <div data-testid="slider">
      <Carousel
        autoPlay
        showStatus={false}
        showThumbs={false}
        swipeable={false}
      >
        {sliders.map(({
          id, image, text, isImage,
        }) => (
          <div className="main-slider" key={id}>
            {isImage ? (
              <img className="main-slider-img" src={image} alt={text} />
            ) : (
              <video autoPlay muted loop controls className="main-slider-video">
                <track kind="captions" srcLang="en" label="English_captions" />
                <source src={image} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </div>
        ))}
      </Carousel>
    </div>
  );

  const renderScrollMenu = () => (
    <div className="section">
      <div className="navbar-categories">
        <ScrollMenu
          alignCenter={false}
          arrowDisabledClass="scroll-menu-arrow--disabled"
          arrowLeft={<span className="arrow-prev">{' < '}</span>}
          arrowRight={<span className="arrow-next">{' > '}</span>}
          data={marcasHome.map((category) => (
            <Link key={category.category.id} to={`/productos/${category.category.code}`}>
              <img alt={category.category.name} src={category.category.image} />
            </Link>
          ))}
          dragging={false}
          hideSingleArrow
          wheel={false}
        />
      </div>
    </div>
  );

  const renderMarcasHome = () => (
    <div className="section">
      <div className="marcasHome">
        {marcasHome.map((category) => (
          <Link key={category.category.id} to={`/productos/${category.category.code}`}>
            <img alt={category.category.name} src={category.category.image} style={{ width: '100%' }} />
          </Link>
        ))}
      </div>
    </div>
  );

  const renderItems = (productos, onClickMetod, current, pos, loading) => (
    <div className="section">
      {subCategoriesHome.filter((subCategory) => (subCategory.position === pos)).length > 0 && (
        <div className="row align-items-center">
          <div className="col text-center">
            <div className="new-arrivals-sorting">
              <ul className="arrivals-grid-sorting clearfix button-group filters-button-group">
                <li
                  className={`grid-sorting-button button ${current === -1 ? 'active' : ''}`}
                  onClick={() => onClickMetod()}
                  role="presentation"
                >
                  Todos los productos
                </li>
                {subCategoriesHome.filter(
                  (subCategory) => (subCategory.position === pos),
                ).map((subCategorie) => (
                  <li
                    className={`grid-sorting-button button ${subCategorie.subCategory_id === current ? 'active' : ''}`}
                    data-testid={`section-${subCategorie.subCategory_id}`}
                    key={subCategorie.subCategory_id}
                    onClick={() => onClickMetod(subCategorie)}
                    role="presentation"
                  >
                    {subCategorie.category.name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      )}
      <div className="row align-items-center product-slider-container" data-testid="carousel">
        {loading && <Loading />}
        {!loading && (
          <>
            {!productos.length && <h4 className="text-center">No se encontraron productos</h4>}
            {!!productos.length && productos.map(renderItem)}
          </>
        )}
      </div>
    </div>
  );

  return (
    <div data-testid="home-page">
      {renderSilder()}
      <div className="container">
        <div className="row">
          <div className="col">
            {renderMarcasHome()}
            {renderScrollMenu()}
            {categoriesHome.length == 5 ? (
              <div className="section">
                <div className="category-images">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="img-button column-flex">
                        <img
                          alt={categoriesHome[0].category.name}
                          src={categoriesHome[0].category.imageHome}
                        />
                        <Link to={`/productos/${categoriesHome[0].category.code}`}>
                          <button type="button">{categoriesHome[0].category.name}</button>
                        </Link>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="row">
                        <div className="col-6 column-flex">
                          <div className="img-button column-flex">
                            <img
                              alt={categoriesHome[1].category.name}
                              src={categoriesHome[1].category.imageHome}
                            />
                            <Link to={`/productos/${categoriesHome[1].category.code}`}>
                              <button type="button">{categoriesHome[1].category.name}</button>
                            </Link>
                          </div>
                        </div>
                        <div className="col-6 column-flex">
                          <div className="img-button column-flex">
                            <img
                              alt={categoriesHome[2].category.name}
                              src={categoriesHome[2].category.imageHome}
                            />
                            <Link to={`/productos/${categoriesHome[2].category.code}`}>
                              <button type="button">{categoriesHome[2].category.name}</button>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6 column-flex">
                          <div className="img-button column-flex">
                            <img
                              alt={categoriesHome[3].category.name}
                              src={categoriesHome[3].category.imageHome}
                            />
                            <Link to={`/productos/${categoriesHome[3].category.code}`}>
                              <button type="button">{categoriesHome[3].category.name}</button>
                            </Link>
                          </div>
                        </div>
                        <div className="col-6 column-flex">
                          <div className="img-button column-flex">
                            <img
                              alt={categoriesHome[4].category.name}
                              src={categoriesHome[4].category.imageHome}
                            />
                            <Link to={`/productos/${categoriesHome[4].category.code}`}>
                              <button type="button">{categoriesHome[4].category.name}</button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : <></>}
            <div className="section">
              <div className="row align-items-center">
                <div className="col text-center">
                  <div className="new-arrivals-sorting">
                    <ul className="arrivals-grid-sorting clearfix button-group filters-button-group">
                      {sectionsArray.map((section) => (
                        <button
                          className={`green-button ${section.value === currentSection.value ? 'active' : ''}`}
                          data-testid={`section-${section.value}`}
                          key={section.value}
                          onClick={() => onSectionClick(section.value)}
                          type="button"
                        >
                          {section.label}
                        </button>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row align-items-center product-slider-container" data-testid="carousel">
                {loadingProd && <Loading />}
                {!loadingProd && (
                  <>
                    {!items.length && <h4 className="text-center">No se encontraron productos</h4>}
                    {!!items.length && items.map((item) => renderItem(item))}
                  </>
                )}
              </div>
              <div className="row justify-content-center">
                <Link to="/productos"><button type="button" className="green-button">Ver todos los productos</button></Link>
              </div>
            </div>
            {instagram.length && (
              <>
                <div className="section">
                  <div className="row align-items-center">
                    <div className="col text-center info-container">
                      <a href={() => false}>Seguinos en Instagram </a>
                      <a href="https://www.instagram.com/lenceriavellaneda/">@lenceriavellaneda</a>
                    </div>
                  </div>
                </div>
                <div className="section social-media">
                  {instagram.map((publicacion) => (
                    <div className="instagram-image">
                      <iframe title="instagram" src={`${publicacion.link}embed`} className="instagram-iframe" frameBorder="0" scrolling="no" allowtransparency="true" />
                    </div>
                  ))}
                </div>
              </>
            )}
            <div className="section">
              <div className="newsletter-conteiner">
                <div className="text-center info-container newsletter-title">
                  <a href={() => false} style={{ color: '#1e1e27' }}>Suscribite a nuestro newsletter</a>
                </div>
                <div className="input-button-group">
                  <div className="input-group">
                    <input className="email-input" onChange={(event) => setNewsletter(event.target.value)} type="email" placeholder="Email..." id="email" pattern=".+@example\.com" size={30} />
                    <div className="input-group-append ">
                      <button className="btn btn-primary-outline newsletter-btn" onClick={submitNewsletter} type="button">ENVIAR</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Home.defaultProps = {
  tiktok: null,
};

Home.propTypes = {
  cartel: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  categoriesHome: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  currentSection: PropTypes.number.isRequired,
  currentSection1: PropTypes.number.isRequired,
  instagram: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  items: productsArrayPropTypes.isRequired,
  loading: PropTypes.bool.isRequired,
  marcasHome: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  mostSelled: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  mostSelledClick: PropTypes.func.isRequired,
  offers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onSectionClick: PropTypes.func.isRequired,
  renderItem: PropTypes.func.isRequired,
  setNewsletter: PropTypes.func.isRequired,
  sliders: slidersArrayPropTypes.isRequired,
  subCategoriesHome: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  submitNewsletter: PropTypes.func.isRequired,
  tiktok: PropTypes.string,
};

export default Home;
