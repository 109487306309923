import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { contact } from 'redux/actions/user.actions';

import Contact from 'views/Contact';

const ContactContainer = (props) => {
  const { contactAction, loading, minimumPurchase } = props;

  return (
    <Contact
      loading={loading}
      minimumPurchase={minimumPurchase}
      onSubmit={contactAction}
    />
  );
};

const mapStateToProps = (state) => ({
  loading: state.user.loading,
  minimumPurchase: state.app.minimumPurchase,
});

const mapDispatchToProps = (dispatch) => ({
  contactAction: bindActionCreators(contact, dispatch),
});

ContactContainer.propTypes = {
  contactAction: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  minimumPurchase: PropTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactContainer);
