import React, { useEffect,useState } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, change } from 'redux-form';
import { useSelector } from 'react-redux';
import { getLocalStorage } from 'helpers/storage';

import Avatar from '@material-ui/core/Avatar';
import ShoppingCart from '@material-ui/icons/ShoppingCart';

import Input from 'components/commons/Input';
import FileInput from 'components/commons/FileInput';
import Button from 'components/commons/Button';

import mercadoPagoImage from 'views/Checkout/assets/mercado-pago.png';

import { DELIVERY_FREE, DELIVERY_PAY, DELIVERY_FREE_PAY } from './constants';
import truckImage from './assets/truck.png';

import './PaymentForm.scss';

const validate = (values) => {
  const errors = {};
  if (!values.delivery_method) {
    errors.delivery_method = 'Debe elegir un método de envío';
  }
  return errors;
};

const PaymentForm = (props) => {
  const {
    deliveryMethod, handleSubmit, onSubmit, submitting, onSecureCheckoutClick, showMPButton,
    transferInfo, dispatch, subtotal, onSubmitTransfer, coupon, envios, subTotEnv,
  } = props;

  const [transfer, setTransfer] = useState(false);

  const handleBack = () => {
    dispatch(change('payment', 'comprobante', ''));
    setTransfer(false);
  };

  const discount = getLocalStorage('discount');
  const percentageOrAmount = coupon?.percentage
    ? (coupon.percentage / 100) * subtotal
    : (coupon?.amount || 0);
  const totalValueWithDiscount = (discount - percentageOrAmount) > 0
    ? discount - percentageOrAmount
    : 0;
  const totalValueWithoutDiscount = (subtotal - percentageOrAmount) > 0
    ? subtotal - percentageOrAmount
    : 0;
  const totalValueWithPercetage = subtotal - percentageOrAmount;

  const handleTransferClick = () => {
    // Validar el formulario al hacer clic en "PAGAR POR TRANSFERENCIA"
    handleSubmit((values) => {
      const errors = validate(values);
      if (Object.keys(errors).length === 0) {
        setTransfer(true); // Mostrar el modal si no hay errores
      }
    })();
  };

  return (
    <div className="container" data-testid="payment-form">
      <div className="row">
        <div className="form-container">
          <Avatar className="avatar">
            <ShoppingCart />
          </Avatar>
          <h4>M&eacute;todo de env&iacute;o</h4>
          <form className="redux-form" encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)}>
          {envios.length > 0 ? envios.map(envio=>(
                <div className="checkout-radio">
                  <Field
                    formGroupClassName={parseInt(deliveryMethod, 10) === envio.id ? 'radio-active' : ''}
                    name="delivery_method"
                    label=""
                    component={Input}
                    type="radio"
                    value={envio.id.toString()}
                    renderRadioDiv={() => (
                    <div className="radio-button-info">
                      <div className="radio-button-image">
                        <img src={truckImage} className="truck-image" alt="envio-CABA-lenceria" />
                      </div>
                      <div className="radio-button-text">
                        <span>{envio.name}</span>
                        <span className="radio-span-subtitle">
                            {envio.monto_minimo > 0 ? `¡ENVIO GRATIS SUPERANDO LOS $${envio.monto_minimo}!` : ""}
                          </span>
                      </div>
                    </div>
                    )}
                  />
                </div>))
              :<></>}
            <Field
              component={Input}
              label="Transporte de preferencia / Comentarios"
              isTextArea
              name="comments"
            />

            <Button text="Arreglar pago con el vendedor" type="submit" buttonClass="btn-block" disabled={submitting} />
            {showMPButton
              && (
              <Button
                text={(
                  <div className="secure-checkout-button-content justify-content-center">
                    <img src={mercadoPagoImage} alt="mercado pago" height="18" />
                    {' '}
                    Pagar con Mercado Pago
                  </div>
                )}
                type="button"
                onClick={handleSubmit(onSecureCheckoutClick)}
                buttonClass="btn-block secure-checkout-button"
                disabled={submitting}
              />
              )}
               {transferInfo ? <>{transferInfo.hide ? <Button text="PAGAR POR TRANSFERENCIA" buttonClass="btn-block" onClick={() => handleTransferClick()} childrenClass='children-Class'  children={transferInfo.discount != 0 &&  `Descuento: ${transferInfo.porcentaje ? transferInfo.discount + '%':'$' + transferInfo.discount}`} /> : <></>}
              {transfer ?
                <div className="modal-confirmCart-overlay">
                  <div className="modal-confirmCart">
                    <div className="modal-confirmCart-content">
                      <h3 className="modal-title">{transferInfo.title}</h3>
                      <p className="modal-description">{transferInfo.description}</p>
                      {!!transferInfo.discount &&  
                      <p className="modal-info">Descuento por pago por transferencia: {transferInfo.porcentaje ? transferInfo.discount + '%':'$' + transferInfo.discount}</p>}
                      <p className="modal-info">{transferInfo.take_discounts ? 'Modo de pago compatible con otros descuentos' : 'Modo de pago no compatible con otros descuentos'}</p>
                      <p className="modal-description">Monto total a pagar:  ${
                        transferInfo.porcentaje ?
                        discount === null || !transferInfo.take_discounts
                        ? (
                          (totalValueWithoutDiscount * (1-(transferInfo.discount/100)) + subTotEnv).toFixed(2)
                        )
                        : (
                              coupon?.percentage ? (
                                (totalValueWithPercetage * (1-(transferInfo.discount/100)) + subTotEnv).toFixed(2)
                              ) : (
                                (totalValueWithDiscount * (1-(transferInfo.discount/100)) + subTotEnv).toFixed(2)
                              )
                        ) :
                        discount === null || !transferInfo.take_discounts
                        ? (
                          (totalValueWithoutDiscount - transferInfo.discount + subTotEnv).toFixed(2)
                        )
                        : (
                              coupon?.percentage ? (
                                (totalValueWithPercetage - transferInfo.discount + subTotEnv).toFixed(2)
                              ) : (
                                (totalValueWithDiscount - transferInfo.discount + subTotEnv).toFixed(2)
                              )
                        )}</p>
                      {/*<Field
                        component={FileInput}
                        label="Comprobante de pago"
                        name="comprobante"
                        type="file"
                      />*/}
                    </div>
                    <div className="modal-confirmCart-actions">
                      <button className="btn-transfer" onClick={handleBack} disabled={submitting}>
                        Volver
                      </button>
                      <button className="btn-transfer" onClick={handleSubmit(onSubmitTransfer)}>
                        Confirmar
                      </button>
                    </div>
                  </div>
                </div>
              :<></>}</> : <></>}
          </form>
        </div>
      </div>
    </div>
  );
};

PaymentForm.defaultProps = {
  deliveryMethod: null,
};

PaymentForm.propTypes = {
  deliveryMethod: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  onSecureCheckoutClick: PropTypes.func.isRequired,
  showMPButton: PropTypes.bool.isRequired,
};

export default reduxForm({
  form: 'payment',
  validate,
})(PaymentForm);
