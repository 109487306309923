import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { categorySelector } from 'redux/selectors/category.selector';
import { scrollTop } from 'helpers/window';
import {
  categoriesArrayPropTypes, categoryPropTypes, productsArrayPropTypes,
} from 'helpers/propTypes';

import ItemContainer from 'containers/ItemContainer';
import Products from 'views/Products';

const limit = 40;

const PaginatorContainer = (props) => {
  const {
    category, categories, loading, maxPrice, minimumPurchase, name, products, url,
    subCategories,
  } = props;
  const [itemsFiltered, setItemsFiltered] = useState([]);
  const priceRange = [0, Math.ceil(maxPrice)];
  const [inputRangeValue, setInputRangeValue] = useState(priceRange);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [items, setItems] = useState([]);
  const [checked, setChecked] = useState(false);
  const [productsLoading, setProductsLoading] = useState(false);
  const [orderProducts, setOrderProducts] = useState(null);
  const [subCategory, setSubCategory] = useState(null);

  const setCurrentPage = (currentPage, currentItems) => {
    const totalItems = Math.ceil(currentItems.length / limit);
    const offset = Math.ceil((currentPage - 1) * limit);
    const itemsPaginated = currentItems.slice(offset, offset + limit);
    scrollTop();
    setPage(currentPage);
    setTotal(totalItems);
    setItems(itemsPaginated);
  };

  const orderFilteredProducts = (productsToFilter) => {
    if (orderProducts) {
      switch (orderProducts.value) {
        case 'A-Z':
          productsToFilter.sort((product, otherProduct) => {
            const fa = product.name.toLowerCase();
            const fb = otherProduct.name.toLowerCase();
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          });
          return productsToFilter;
        case 'Z-A':
          productsToFilter.sort((product, otherProduct) => {
            const fa = product.name.toLowerCase();
            const fb = otherProduct.name.toLowerCase();
            if (fa > fb) {
              return -1;
            }
            if (fa < fb) {
              return 1;
            }
            return 0;
          });
          return productsToFilter;
        case 'ascendente':
          productsToFilter.sort((product, otherProduct) => product.price - otherProduct.price);
          return productsToFilter;
        case 'descendente':
          productsToFilter.sort((product, otherProduct) => otherProduct.price - product.price);
          return productsToFilter;
        default:
          return productsToFilter;
      }
    }
    return productsToFilter;
  };

  const filterByPrice = (product) => {
    const min = inputRangeValue[0];
    const max = inputRangeValue[1] === 0 ? Math.ceil(maxPrice) : inputRangeValue[1];
    return product.sale
      ? product.sale >= min && product.sale <= max
      : product.price >= min && product.price <= max;
  };

  const filterBySale = (product) => (
    checked
      ? product.sale > 0
      : true);

  const filterByCategories = (product) => (
    !category || product.categories.some((cat) => cat.code === category.code)
  );

  const filterBySubCategories = (product) => (
    !subCategory || product.sub_categories.some((cat) => cat.code === subCategory.code)
  );

  const filterItems = () => {
    const filtered = products.filter((product) => (
      filterByPrice(product)
      && filterByCategories(product)
      && filterBySale(product)
      && filterBySubCategories(product)
    ));
    const productsFiltered = orderFilteredProducts(filtered);
    setItemsFiltered(productsFiltered);
    setCurrentPage(1, productsFiltered);
  };

  useEffect(() => {
    if (products.length > 0 && maxPrice !== 0) {
      filterItems();
    }
    if(products.length === 0){
      filterItems();
    }
    // eslint-disable-next-line
  }, [category, categories, loading, maxPrice, products]);

  useEffect(() => {
    if(itemsFiltered.length === 0 && products.length > 0){
      setProductsLoading(false);
    }else if(itemsFiltered.length === 0 && products.length === 0){
      setProductsLoading(false);
    } 
  }, [itemsFiltered]);

  const handlePaginationChange = (event, currentPage) => {
    setCurrentPage(currentPage, itemsFiltered);
  };

  const handleInputRangeChange = (event, value) => {
    setInputRangeValue(value);
  };

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  const handleSubCategory = (subCat) => {
    setSubCategory(subCat);
  };

  const renderItem = (item) => (
    <div className="col-products margin-product" style={{ background: 'rgb(249 249 249)' }} key={`item-${item.id}`}>
      <ItemContainer item={item} />
    </div>
  );

  return (
    <Products
      category={category}
      categories={categories}
      filterItems={filterItems}
      handleInputRangeChange={handleInputRangeChange}
      handlePaginationChange={handlePaginationChange}
      handleSubCategory={handleSubCategory}
      inputRangeValue={inputRangeValue}
      checked={checked}
      handleCheckboxChange={handleCheckboxChange}
      setOrderProducts={setOrderProducts}
      orderProducts={orderProducts}
      items={items}
      loading={loading}
      maxPrice={maxPrice}
      minimumPurchase={minimumPurchase}
      name={name}
      page={page}
      renderItem={renderItem}
      productsLoading={productsLoading}
      subCategories={subCategories}
      subCategory={subCategory}
      total={total}
      url={url}
    />
  );
};

const mapStateToProps = (state, props) => ({
  category: categorySelector(state, props),
  categories: state.category.items,
  loading: state.product.loading,
  maxPrice: state.product.maxPrice,
  minimumPurchase: state.app.minimumPurchase,
  subCategories: state.subCategory.items,
});

PaginatorContainer.defaultProps = {
  category: null,
};

PaginatorContainer.propTypes = {
  category: categoryPropTypes,
  categories: categoriesArrayPropTypes.isRequired,
  loading: PropTypes.bool.isRequired,
  maxPrice: PropTypes.number.isRequired,
  minimumPurchase: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  products: productsArrayPropTypes.isRequired,
  url: PropTypes.string.isRequired,
  subCategories: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default connect(mapStateToProps)(PaginatorContainer);
