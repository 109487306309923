import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { categoriesArrayPropTypes } from 'helpers/propTypes';

import Logo from 'components/main/Header/Logo';

import paymentImage from './assets/icons_payment.png';
import './Footer.scss';

const Footer = (props) => {
  const { marcasHome, subCategoriesHome } = props;

  const year = new Date().getFullYear();

  const scrollToTop = () => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

  return (
    <footer className="footer" data-testid="footer">
      <div>
        <div className="row footer-top">
          <div className="col-lg-1 col-md-12" />
          <div className="col-lg-3 col-md-12 footer-column">
            <Logo />
            <ul>
              <li>
                Somos uno de los fabricantes y revendedores más grandes del país con 30 años
                {' '}
                de experiencia en el rubro
              </li>
              <li className="social-links">
                <a href="https://www.facebook.com/LenceriAvellaneda" target="_blank" rel="noopener noreferrer">
                  <i className="fa fa-facebook" />
                </a>
                <a href="https://instagram.com/lenceriavellaneda?igshid=YmMyMTA2M2Y=" target="_blank" rel="noopener noreferrer">
                  <i className="fa fa-instagram" />
                </a>
                {/* <a href="https://www.tiktok.com/@lenceriaavellaneda" target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-tiktok" />
                </a>  */}
              </li>
            </ul>
          </div>
          <div className="col-lg-8 footer-column">
            <div className="row">
              <div className="col-4 col-md-4 footer-column">
                <span className="title">Secciones</span>
                <ul>
                  <li><Link to="/home">Homepage</Link></li>
                  <li><Link to="/contacto">Nosotros</Link></li>
                  <li><Link to="/preguntas-frecuentes">FAQs frecuentes</Link></li>
                  <li><Link to="/contacto">Contacto</Link></li>
                  <li><Link to="/contacto">Local</Link></li>
                </ul>
              </div>
              <div className="col-4 col-md-4 footer-column">
                <span className="title">Marcas</span>
                <ul>
                  {marcasHome ? marcasHome.map((marca) => (
                    <li><Link to={`/productos/${marca.category.code}`}>{marca.category.name}</Link></li>
                  )) : <></>}
                </ul>
              </div>
              <div className="col-4 col-md-4 footer-column">
                <span className="title">Productos</span>
                <ul>
                  {subCategoriesHome ? subCategoriesHome.map((subCategory) => (
                    <li><span href={() => false}>{subCategory.category.name}</span></li>
                  )) : <></>}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-wrap d-md-flex">
          <div className="bottom col-lg-4">
            &copy;
            {' '}
            {year}
            {' '}
            Lencería avellaneda, Inc.
          </div>
          <div className="bottom col-lg-4">
            <img alt="forma de pago" src={paymentImage} style={{ objectFit: 'contain' }} />
          </div>
          <div className="bottom col-lg-4">
            <button className="scrollTop" onClick={scrollToTop} type="button">
              Ir al inicio
              <i className="fa fa-arrow-up" style={{ paddingLeft: '5px' }} aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
      <div className="jaypion col-lg-12">
        &copy;
        {' '}
        {year}
        {' '}
        Derechos reservados por&nbsp;
        <a href="https://info.jaypionba.com/" target="_blank" rel="noopener noreferrer">
          Jaypion
        </a>
      </div>
    </footer>
  );
};

const mapStateToProps = (state) => ({
  marcasHome: state.category.marcasHome,
  subCategoriesHome: state.subCategory.itemsHome,
});

Footer.propTypes = {
  marcasHome: categoriesArrayPropTypes.isRequired,
  subCategoriesHome: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default connect(mapStateToProps)(Footer);
