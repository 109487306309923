import React from 'react';
import PropTypes from 'prop-types';

import QuantitySelector from 'components/commons/QuantitySelector';

import './DescriptionItem.scss';

const DescriptionItem = (props) => {
  const {
    description, handleColorSelect, onQuantityChange, showQuantitySelector, storage,
    othersWholesale, storageSub, othersWholesaleSub, showColors,
  } = props;
  const quantity = storage ? storage.quantity : (storageSub?.quantity || 0);
  // eslint-disable-next-line no-nested-ternary
  const quantityOtherWholesale = othersWholesale.length > 0
    ? othersWholesale.map((otherProduct) => (otherProduct.quantity))
    : (othersWholesaleSub.length > 0
      ? othersWholesaleSub.map((otherProduct) => (otherProduct.quantity))
      : [0]
    );

  return description.stock_descriptions.stock_category_id === 1
    && description.stock_descriptions.rgb ? (
      <>
        {showColors && (
          <div className="color-container" onClick={() => handleColorSelect(description)}>
            <span className="color-circle" style={{ backgroundColor: description.stock_descriptions.rgb }}  role="presentation" />
            <span className="color-name">{description.stock_descriptions.name}</span>
          </div>
        )}
        {showQuantitySelector && (
          <QuantitySelector
            limit={description.stock}
            onQuantityChange={onQuantityChange}
            quantity={quantity}
            description={description}
            quantityOthersWholesale={quantityOtherWholesale}
          />
        )}
      </>
    ) : (
      <div className="d-flex flex-column align-items-center">
        <span className="description-name">{description.stock_descriptions.name}</span>
        <QuantitySelector
          limit={description.stock}
          onQuantityChange={onQuantityChange}
          quantity={quantity}
          description={description}
          quantityOthersWholesale={quantityOtherWholesale}
        />
      </div>
    );
};

DescriptionItem.defaultProps = {
  handleColorSelect: () => {},
  othersWholesale: [],
  othersWholesaleSub: [],
  showQuantitySelector: false,
  storage: null,
  storageSub: null,
  showColors: true,
};

DescriptionItem.propTypes = {
  description: PropTypes.shape().isRequired,
  handleColorSelect: PropTypes.func,
  onQuantityChange: PropTypes.func.isRequired,
  showQuantitySelector: PropTypes.bool,
  showColors: PropTypes.bool.isRequired,
  storage: PropTypes.shape({
    quantity: PropTypes.number,
  }),
  storageSub: PropTypes.shape({
    quantity: PropTypes.number,
  }),
  othersWholesale: PropTypes.arrayOf(PropTypes.shape()),
  othersWholesaleSub: PropTypes.arrayOf(PropTypes.shape()),
};

export default DescriptionItem;
